import React, { Component, Fragment } from 'react'
import Cookies from 'universal-cookie'
import { config } from '../../config.js'
import { haversine_distance, distance_in_minutes } from '../../utils/helpers.js'
import moment from 'moment'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CardActionArea,
  Icon,
  Typography,
  Grid,
  withStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import ItineraryItemDialog from './ItineraryItemDialog'
import ItineraryItem from './ItineraryItem'
import ItineraryInput from './ItineraryInput'
import UnlockItinerary from '../forms/UnlockItinerary'
import SearchPanel from '../SearchPanel'

const API = config.api.TRAVEL_URL
const cookies = new Cookies();


class Itinerary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isEditingItineraryItemId: null,
      itinerary: props.itinerary || [],

      isRefreshing: props.isRefreshing || null,
      refreshIntervalId: null,
    }

    if (props.isRefreshing) {
      this.generateItem()
    }
  }

  componentDidUpdate(prevProps){
    if ((prevProps.isRefreshing !== this.props.isRefreshing) && this.state.refreshIntervalId === null) {
      this.generateItem()
    }
  }

  static getDerivedStateFromProps(props, state) {
    if ((props.itinerary && props.itinerary.length > 0) && state.itinerary.length < 1) {
      return {
        itinerary: props.itinerary || [],
      };
    }

    if (props.isRefreshing && state.isRefreshing === null) {
      return {
        isRefreshing: props.isRefreshing || [],
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  newItineraryItem = (item) => {
    let itinerary = this.state.itinerary
    itinerary.push(item)
    this.setState({itinerary: itinerary, isEditingItineraryItemId: null})
  }

  editItineraryItem = (item) => {
    this.setState({isEditingItineraryItemId: item.id})
  }

  cancelItineraryItem = (item) => {
    if (item.id) {
      this.setState({isEditingItineraryItemId: null})
    } else {
      let itinerary = this.state.itinerary
      let index = itinerary.findIndex((i) => {
        // console.log(i.id, item.id);
        return i.id === item.id
      })
      itinerary.splice(index, 1)
      this.setState({itinerary: itinerary, isEditingItineraryItemId: null})
    }
  }

  savedItinerary = (itinerary) => {
    this.setState({itinerary: itinerary, isEditingItineraryItemId: null})
  }

  refreshItinerary = () => {
    fetch(API + `/${this.props.itineraryType === "Trip" ? 'trips' : 'trips'}/${this.props.itineraryId}/program?`
    ,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid'),
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.itinerary) {
        if(data.planned) {
          this.setState({itinerary: data.itinerary, isRefreshing: false})
          clearInterval(this.state.refreshIntervalId);
        } else {
          this.setState({itinerary: data.itinerary})
        }
      } else {
        this.setState({error: data.error})
        setTimeout(function( ) { clearInterval(this.state.refreshIntervalId); this.setState({isRefreshing: false}); }.bind(this), 120000);
      }
    })
  }

  createItineraryItem = (type, params) => {
    fetch(API + `/${this.props.itineraryType === "Trip" ? 'trips' : 'trips'}/${this.props.itineraryId}/program?`
    ,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid'),
      },
      dataType: 'json',
      body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(data => {

    })
  }

  generateItem = (type, params) => {
    // generate item or check if item is generated
    if (type && params) {
      this.createItineraryItem(type, params)
    } else {
      this.refreshItinerary()
    }

    var refreshIntervalId = setInterval(this.refreshItinerary, 5000);
    this.setState({refreshIntervalId: refreshIntervalId, isRefreshing: true});
  }

  isSize = (size) => {
    return isWidthDown(size, this.props.width)
  }

  handleSignin = (user) => {
    // save travel preference when user is new
    if (!user.travel_preference && this.state.itinerary.travel_preference) {
      this.savePreferences(user, this.state.itinerary.travel_preference)
      user.travel_preference = this.state.itinerary.travel_preference
    }

    this.props.onSignin(user)
    this.refreshItinerary()
  }

  render() {
    const { classes, itineraryType, itineraryId, itineraryUrl, itineraryLoaded, destination, user, search_params, editable, showAiAssistant } = this.props;
    const { itinerary, isRefreshing } = this.state

    const LoadingItem = ({ messageItem }) => {
      return <ItineraryItem key={"loading"}
        user={{}}
        isEditing={false}
        onEditItem={() => {}}
        onCancel={() => {}}
        onSavedItinerary={() => {}}
        item={messageItem}
        itineraryType={""}
        itineraryId={null}
        destination={{}}
        search_params={{}}
      />
    }

    return(
      <Fragment>
        {itineraryLoaded ?
        <Fragment>
          <div className={classes.route}>
            <Fragment>
              {/* {<div style={{padding: '50px 0px 20px 0px'}}>
                <SearchPanel
                  disabled={true}
                  planner={false}
                  center={false}
                  hideFieldTo={true}
                  hideSearchButton={false}
                  show_description={false}
                  expandable={this.isSize('xs')}
                  search_params={this.props.search_params}
                  onSearch={this.props.onSearch}
                />
              </div>} */}

              {itinerary.sort((a, b) => { return (a.day !== b.day ? (a.day < b.day ? -1 : 1) : (a.order < b.order ? -1 : 1)) }).map((item, i) => {
                return <Fragment key={i}>

                  {/* Day */}
                  {(item.day > 1 && (itinerary[i - 1] === undefined || itinerary[i - 1].day !== item.day)) &&
                    (destination.start_date ?
                      <Typography className={classes.itineraryDay}>
                        {`Day ${item.day}`}
                        {`: `}{moment(destination.start_date).add(item.day - 1, 'days').format('MMM DD')}
                        {', '}{moment(destination.start_date).add(item.day - 1, 'days').format('ddd')}
                      </Typography>
                    :
                      <Typography className={classes.itineraryDay}>
                        {`Day ${item.day}`}
                      </Typography>
                    )
                  }

                  <ItineraryItem key={i}
                    order={i}
                    user={user}
                    editable={editable}
                    isEditing={item.id === this.state.isEditingItineraryItemId}
                    onEditItem={this.editItineraryItem}
                    onCancel={this.cancelItineraryItem}
                    onSavedItinerary={this.savedItinerary}
                    item={item}
                    itineraryType={itineraryType}
                    itineraryId={itineraryId}
                    destination={destination}
                    search_params={search_params}
                  />

                  {false && item.latitude && item.longitude && (itinerary.length > i+1) && (itinerary[i + 1].latitude && itinerary[i + 1].longitude) && <div className={classes.routeDrivingInfo}>
                    <Card className={classes.routeDrivingCard} variant="outlined">
                      <CardHeader classes={{root: classes.routeDrivingCardHeader, title: classes.routeDrivingCardTitle, subheader: classes.routeDrivingCardSubtitle}}
                        avatar={
                          <Avatar aria-label="icon" className={classes.routeIconSmall}>
                            <Icon className={`${classes.routeDrivingIcon} notranslate`}>directions_car</Icon>
                          </Avatar>
                        }
                        subheader={<Fragment>
                          <div className={classes.routeDrivingTime}>
                            {`Est. `}
                            {haversine_distance([item.latitude, item.longitude], [itinerary[i + 1].latitude, itinerary[i + 1].longitude]) > 0 ?
                              <Fragment>
                                {Math.floor(distance_in_minutes([item.latitude, item.longitude], [itinerary[i + 1].latitude, itinerary[i + 1].longitude]) / 60) > 0 && `${Math.floor(distance_in_minutes([item.latitude, item.longitude], [itinerary[i + 1].latitude, itinerary[i + 1].longitude]) / 60)} hr, `}
                                {Math.floor(distance_in_minutes([item.latitude, item.longitude], [itinerary[i + 1].latitude, itinerary[i + 1].longitude]) / 60) > 0 ? distance_in_minutes([item.latitude, item.longitude], [itinerary[i + 1].latitude, itinerary[i + 1].longitude]) - Math.floor(distance_in_minutes([item.latitude, item.longitude], [itinerary[i + 1].latitude, itinerary[i + 1].longitude]) / 60) * 60 : Math.round(distance_in_minutes([item.latitude, item.longitude], [itinerary[i + 1].latitude, itinerary[i + 1].longitude]))} min
                                {' '}&bull;{' '}
                                {`${haversine_distance([item.latitude, item.longitude], [itinerary[i + 1].latitude, itinerary[i + 1].longitude])}km`}
                              </Fragment>
                              :
                              <Fragment>
                                less than 1 min.
                              </Fragment>}
                          </div>
                        </Fragment>}
                      />
                    </Card>
                  </div>}


                </Fragment>
              })}
            </Fragment>

            {/* Day */}
            {itinerary.length > 0 ?
              <Fragment>
                {(itinerary[itinerary.length - 1].day < destination.nights_in_dest + 1) &&
                <Typography className={classes.itineraryDay}>
                  <strong style={{fontWeight: 800}}>{`Day ${itinerary[itinerary.length - 1].day + 1} ${itinerary[itinerary.length - 1].day + 1 !== destination.nights_in_dest + 1 ? " - " + (destination.nights_in_dest + 1) : ''} `}</strong>
                </Typography>}
              </Fragment>
              :
              <Fragment>
                <Typography className={classes.itineraryDay}>
                  <strong style={{fontWeight: 800}}>{`Day 1`}</strong>
                </Typography>
              </Fragment>
            }


            {/* Add to trip */}
            {/* <Box><ItineraryItemDialog onCreate={this.newItineraryItem}/></Box> */}
            {/* {(itinerary && itinerary.length > 0 && itinerary[itinerary.length - 1].id === undefined) ?
              <Fragment></Fragment>
              :
              <Fragment>
                {user.is_registered && <Box><ItineraryItemDialog onCreate={this.newItineraryItem}/></Box>}
              </Fragment>
            } */}

            {/* Itinerary is generating */}
            {showAiAssistant && <Fragment>
            {itinerary.filter((item) => item.category === 'program').length < 1 &&
              (isRefreshing ?
                <Fragment>
                  <LoadingItem messageItem={{id: 'ai', category: "ai", title: "Generating trip itinerary...", time: "It might take couple of minutes for longer trips", isLoading: true }} />
                </Fragment>
                :
                <Fragment>
                  <ItineraryInput onGenerateItem={this.generateItem} user={user}/>
                </Fragment>

              )
            }
            </Fragment>}
          </div>



          {/* Show 1-day itinerary and request signup for  */}
          {!user.is_registered && itinerary.filter((item) => item.category === 'program').length > 0 &&
            <UnlockItinerary user={user} onSignin={this.handleSignin} />
          }
        </Fragment>
        :
        <div style={{padding: 10}}>
          <Skeleton variant="rect" height={30} width={'300px'} className={classes.skeleton} style={{marginTop: 20, borderRadius: 10, marginLeft: 0, marginBottom: 20}}/>
          <Skeleton variant="rect" height={100} width={'auto'} className={classes.skeleton} style={{marginTop: 0, borderRadius: 15, marginBottom: 0}}/>
        </div>
        }


      </Fragment>
    )
  }
}

const styles = theme => ({
  route: {
    position: 'relative',
    maxWidth: 750,
  },
  skeleton: {
    borderRadius: 10,
  },
  itineraryDay: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '18px',
    padding: '15px 20px',
    backgroundColor: '#f8f8f8',
    zIndex: 1,
    position: 'relative',
    display: 'inline-block',
    borderRadius: 15,
    margin: '20px 0px',
    textAlign: 'center',
  },
  routeDrivingInfo: {
    position: 'relative',
    padding: '0px 0px 15px 10px',
    color: '#999',

    '&::before': {
      position: 'absolute',
      zIndex: 1,
      left: 31,
      width: 2,
      content: '""',
      top: -5,
      height: '108%',
      background: '#f2f2f2',
    },
  },
  routeDrivingCard: {
    maxWidth: 500,
    // border: '1px solid rgba(0, 0, 0, 0.08)',
    border: 'none',
    borderRadius: 25,
    // backgroundColor: '#fafafa',
    fontSize: 600,
    position: 'relative',
    marginLeft: '-2px',
    zIndex: 1,
    // display: 'none',
  },
  routeDrivingCardHeader: {
    padding: '0px 8px',
  },
  routeDrivingCardTitle: {
    fontWeight: 600,
    fontSize: 13,
  },
  routeDrivingCardSubtitle: {
    fontWeight: 600,
    fontSize: 13,
    color: '#999',
  },
  routeDrivingTime: {
    color: '#999',
  },
  routeDrivingIcon: {
    fontSize: '1.3rem',
  },
  routeIconSmall: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: 'transparent',
    color: '#c1c1c1',
    marginRight: '-6px',
  },
});

export default withStyles(styles)(withWidth()(Itinerary));