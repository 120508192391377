import React, { Component } from 'react'
import { Router, Route, Redirect, Switch, withRouter } from 'react-router-dom'
import kebabCase from 'lodash/kebabCase'
import Cookies from 'universal-cookie'

import {
  Grid,
  Icon,
} from '@material-ui/core'
import InstagramIcon from '@material-ui/icons/Instagram'
import { count_stops } from '../utils/helpers'
import Auth from './Auth'
import Assistant from './Assistant.js'
import Trip from './Trip'
import Trips from './Trips'
import Planner from './Planner.js'
import Explore from './Explore'
import HowItWorks from './pages/HowItWorks.js'
import Home from './pages/Home.js'
import Pricing from './pages/Pricing.js'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Unsubscribe from './pages/Unsubscribe.js'
import UserMenu from './user/UserMenu'
import UserQuiz from './user/UserQuiz'
import UserPreferences from './user/UserPreferences'

import './App.css'
import { config } from '../config.js'
import { Fragment } from 'react'

const API = config.api.TRAVEL_URL;
const cookies = new Cookies();


class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      search_params: {
        travelers: {
          adults: 1,
          children: 0,
          infants: 0,
        },
        dates: {
          dates_type: 'cheapest',
          start_date: null,
          end_date: null,
          nights: [2, 3],
        },
        from: null,
        to: null,
        interests: '',
      },
      trip_params: {
        // store params for a trip
      },
      user: {
        is_registered: (cookies.get('jwt') && cookies.get('jwt') !== undefined ? true : false),
        is_onboarded: cookies.get('is_onboarded') ? cookies.get('is_onboarded') : 'USD',
        currency: cookies.get('curr') ? cookies.get('curr') : 'USD',
      },
      // address: '',
      // countryCode: '',
      // addressType: '',
      // trip_id: '',
      // flight: null,
      // hotel: null,
      isLoading: false
    }
  }

  componentDidMount() {
    this.getUser()
  }

  setUser = function(user) {
    if (user.currency && this.state.user.currency !== user.currency) {
      cookies.set('curr', user.currency, {
        path: '/',
        domain: config.api.DOMAIN,
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      })
    }

    if (user.is_onboarded) {
      cookies.set('is_onboarded', user.is_onboarded, {
        path: '/',
        domain: config.api.DOMAIN,
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      })
    }

    this.setState({user: user})
  }

  getUser = () => {
    let jwt = cookies.get('jwt')

    if (jwt) {
      fetch(config.api.TRAVEL_URL + '/users/me', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
        dataType: 'json'})
      .then(response => {
        if (response.status === 401) {
          cookies.remove('jwt', { path: '/', domain: config.api.DOMAIN });
          this.setState({user: {is_registered: false}})
        }
        return response.json()
      })
      .then(data => {
        if (!data.error) {
          // window.gtag('config', 'UA-59718342-1', {user_id: data.referral_token})
          this.setUser(data)
        }
      })
    }
  }

  handleSearch(search_params = {}) {
    // remove null values
    let { destination, dates, ...rest } = search_params
    let dates_params = {}

    if (Object.keys(dates).length > 0) {
      dates_params = Object.entries(dates).reduce((a,[k,v]) => (v === null ? a : (a[k]=v, a)), {})
    }

    // console.log(dates_params)

    if (Object.keys(dates_params).length > 0) {
      dates_params = {dates: dates_params}
    }

    // console.log(dates_params)

    search_params = {...rest, ...dates_params}

    // console.log(search_params)

    let params = {...this.state.search_params, ...search_params}
    let to = params.to && params.to[0]
    let from = params.from && params.from[0]

    // console.log(params)

    if (params.to && params.to.length === 1 && !['country'].includes(to.location_type)) {
      const travel_plan_id = params.travel_plan_id
      const group_id = params.group_id

      this.setState({
        search_params: params,
        isLoading: true
      }, this.props.history.push({
          pathname: `${group_id ? '/group/' + group_id : ''}${travel_plan_id ? '/plan/' + travel_plan_id : ''}/trip${from ? '/' + from.slug : ''}/to/${to.slug}`,
          state: {
            destination: destination,
          }
        })
      );

    } else if (params.to && params.to.length === 1 && ['country'].includes(to.location_type)) {
      this.setState({
        search_params: params,
        isLoading: true
      }, this.props.history.push(`/explore/${(params.interests !== '' && params.interests !== undefined) ? params.interests : 'all'}${to.country ? '/in/' + kebabCase(to.country) : ''}${from ? '/from/' + from.slug : ''}`));

    } else if ((params.to && params.to.length === 0) || !params.to) {
      this.setState({
        search_params: params,
        isLoading: true
      }, this.props.history.push(`/explore/${(params.interests !== '' && params.interests !== undefined) ? params.interests : 'all'}${from ? '/from/' + from.slug : ''}`));

    } else {
      this.setState({
        search_params: params,
        isLoading: true
      }, this.props.history.push(`/explore/multiple${from ? '/from/' + from.slug : ''}`))
      // }, this.props.history.push(`/explore/${params.interests !== '' ? params.interests : 'staycations'}/${kebabCase(location.address) ? 'in/' + kebabCase(location.address) + '/' : ''}from/${location.locationFrom || 'nearby'}`))
    }
  }

  saveFlight(flight, trip_id, locationFrom, locationTo) {
    fetch(API + `/trips/${trip_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({flight: flight})
    })
    .then(response => response.json())
    .then(data => {
      this.setState((prevState) => ({
        flight: flight,
        destination: {
          from: data.destination && data.destination.from,
          to: data.destination && data.destination.to,
          adults: data.destination && data.destination.adults,
          children: data.destination && data.destination.children,
          infants: data.destination && data.destination.infants,
          start_date: flight.dTime,
          end_date: count_stops(flight.route, 1)[0].dTime,
          nights_in_dest: flight.nightsInDest,
          images: prevState.destination && prevState.destination.images,
        }
      }), this.props.history.push(`/hotels/in/${locationTo}/from/${locationFrom}/${trip_id}`));
    })
  }

  saveHotel(hotel, trip_id, locationFrom, locationTo) {
    fetch(API + `/trips/${trip_id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ hotel: hotel })
    })
    .then(response => response.json())
    .then(data => {
      this.setState({
        hotel: hotel
      }, this.props.history.push(`/trip${(locationFrom && locationFrom !== 'nearby') ? '/' + locationFrom : ''}/to/${locationTo}/${trip_id}`));
    })
  }

  updateSearchParams(params) {
    this.setState({
      search_params: {...this.state.search_params, ...params}
    })
  }

  updateTrip(params) {
    this.setState(params)
  }

  render() {
    return (
      <Fragment>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={this.props.history}>
        <Switch>
          {this.state.user.is_registered &&
            <Route exact path={["/assistant"]} render={({match}) => {
              return <Assistant search_params={this.state.search_params} onSignin={this.setUser.bind(this)} user={this.state.user}/>
          }}/>}
          <Route exact path={["/trips", "/trips/:category?"]} render={({match}) => {
            return <Trips onSignin={this.setUser.bind(this)} user={this.state.user} />
          }}/>
          <Route exact path={["/planner/:step?"]} render={({match}) => {
            return <Planner
                    onRefreshUser={this.getUser}
                    onSignin={this.setUser.bind(this)}
                    step={match.params.step || (this.props.location.state && this.props.location.state.step) || 'occasion'}
                    user={this.state.user}
                  />
          }}/>

          <Route exact path={["/", "/from/:locationFrom?", "/dribbble"]} render={({match}) => {
            return <Home
                    locationFrom={match.params.locationFrom || 'nearby'}
                    search_params={this.state.search_params}
                    onSearch={this.handleSearch.bind(this)}
                    onSignin={this.setUser.bind(this)}
                    user={this.state.user}
                   />
          }}/>

          <Route exact path={[
              "/explore/:interests?/in/:country/from/:locationFrom?",
              "/explore/:interests?/in/:country",
              "/explore/:interests?/from/:locationFrom?",
              "/explore/:interests?/tags/:tags",
              "/explore/:interests?/destination/:destination",
              "/explore/:interests?",
            ]} render={({match}) => {
            return <Explore search_params={this.state.search_params}
                    onSignin={this.setUser.bind(this)}
                    onSearch={this.handleSearch.bind(this)}
                    interests={match.params.interests}
                    country={match.params.country}
                    locationFrom={match.params.locationFrom}
                    tabSelected={'explore'}
                    bigHeader={'Explore the next destination'}
                    user={this.state.user}
                    tags={match.params.tags}
                    destination={match.params.destination}
                    hideSearchBar={false}
                    />
          }}/>
          <Route exact path={[
              "/trips/to/:locationTo/from/:locationFrom/:id?",
              "/trips/to/:locationTo/:id?",
              "/trip/:locationFrom/to/:locationTo/:id?",
              "/trip/to/:locationTo/:id?",

              "/trips/to/:locationTo/from/:locationFrom/:tab/:id?",
              "/trips/to/:locationTo/:tab/:id?",
              "/trip/:locationFrom/to/:locationTo/:tab/:id?",
              "/trip/to/:locationTo/:tab/:id?",
            ]}
            render={({match})=> {
              return <Trip search_params={this.state.search_params}
                      locationFrom={match.params.locationFrom || 'nearby'}
                      locationTo={match.params.locationTo}
                      trip_id={match.params.id}
                      travel_plan_id={match.params.travel_plan_id}
                      group_id={match.params.group_id}
                      onSignin={this.setUser.bind(this)}
                      user={this.state.user}
                      backButtonPath={this.props.location && this.props.location.state ? this.props.location.state.backButtonPath : null}
                      selectedTab={match.params.tab}/>
          }}/>

          <Route exact path={["/auth/:jwt?"]} render={({match}) => {
            return <Auth jwt={match.params.jwt}/>
          }}/>

          {this.state.user.is_registered &&
            <Route exact path="/pricing" render={({match}) => {
              return <Pricing onSignin={this.setUser.bind(this)} user={this.state.user}/>
          }}/>}
          {this.state.user.is_registered &&
            <Route exact path="/user/menu" render={({match}) => {
              return <UserMenu onSignin={this.setUser.bind(this)} user={this.state.user}/>
          }}/>}
          {this.state.user.is_registered &&
            <Route exact path="/user/preferences" render={({match}) => {
              return <UserPreferences onSignin={this.setUser.bind(this)} user={this.state.user}/>
          }}/>}
          {this.state.user.is_registered &&
            <Route exact path={["/user/personalize"]} render={({match}) => {
              return <UserQuiz onSignin={this.setUser.bind(this)} onUpdateUser={this.setUser.bind(this)} user={this.state.user}/>
          }}/>}
          <Route exact path="/pages/privacy-policy" render={({match}) => {
            return <PrivacyPolicy onSignin={this.setUser.bind(this)} user={this.state.user}/>
          }}/>
          <Route exact path="/pages/how-it-works" render={({match}) => {
            return <HowItWorks onSignin={this.setUser.bind(this)} user={this.state.user}/>
          }}/>
          <Route exact path="/unsubscribe/discover-weekly/:token" render={({match}) => {
            return <Unsubscribe onSignin={this.setUser.bind(this)} token={match.params.token} group="discover-weekly" user={this.state.user}/>
          }}/>

          <Redirect to="/" />
        </Switch>
      </Router>

      {(!(/^\/personalize|^\/travel-style-quiz|^\/planner|^\/trip/.test(this.props.location.pathname)) && !this.props.location.pathname.startsWith('/auth')) && <div className="footer">
        <Grid item container alignItems={'center'} justify={'center'} className="grid-filters">
          <Grid item xs={12} sm={7} md={7}>
            <span className="notranslate logo">
              Travelities
            </span>
            <span className="menu" style={{float: 'left', marginLeft: -5}}>
              <span className="separator"><Icon className="notranslate">fiber_manual_record</Icon></span>
              <a href="/pages/privacy-policy" rel="nofollow" target='_blank'>Privacy policy</a>
              <span className="separator"><Icon className="notranslate">fiber_manual_record</Icon></span>
              <a href="https://forms.gle/wDpEqv3ufk4YLAzDA" rel="noopener noreferrer" target="_blank">Say Hi 👋 </a>
            </span>

          </Grid>
          <Grid item xs={12} sm={5} md={5}>

            <span className="menu">
              <span style={{color: '#828282', fontSize: 15, verticalAlign: 'middle', paddingRight: 6, display: 'inline-block', marginTop: 2, marginBottom: 5, marginLeft: 10}}>&copy;</span>
              <span style={{color: '#828282', fontWeight: 600}}>{(new Date()).getFullYear()} Resa, inc. All rights reserved.</span>
              <a href="https://www.instagram.com/travelities" rel="noopener noreferrer" target="_blank" className="instagram" style={{marginLeft: 0, padding: '5px 5px 5px 10px'}} alt="instagram"><InstagramIcon></InstagramIcon></a>
            </span>
          </Grid>
        </Grid>
      </div>}
    </Fragment>
    );
  }
}

export default withRouter(App);
