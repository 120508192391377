import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import moment from 'moment'


class TripBookingBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, travel_price, hotel_price, travelers, start_date, end_date, nights, currency } = this.props

    return (
      ((travel_price > 0 || hotel_price > 0) ?
        <Grid container alignItems={'center'} justify={'center'} style={{maxWidth: 1130, margin: 'auto auto 20px auto', left: 'auto'}}>
          <Grid item xs={12} md={12} style={{ margin: 'auto auto auto auto'}}>
            <Paper className={`${classes.paper}`} elevation={0}>
              <Grid container>
                <Grid item xs={12}>
                  <div style={{display: 'block', position: 'relative', float: 'none'}}>
                      <Typography className={classes.section} style={{fontSize: 14, border: 'none', float: 'left', display: 'block'}}>
                        <Fragment>{start_date !== end_date ? moment(start_date).format('MMM D - ') : moment(start_date).format('MMM ')} {moment(start_date).format('MMM') === moment(end_date).format('MMM') ? moment(end_date).format('D') : moment(end_date).format('MMM D')}</Fragment> <br/>
                        <small style={{fontWeight: 500, color: '#333'}}>
                          {nights ? nights + 1 : 1}-day{nights > 0 ? 's' : ''} {hotel_price > 0 ? 'stay' : ''} {travel_price && hotel_price ? '&' : ''} {travel_price > 0 ? 'travel' : ''}</small>
                      </Typography>

                      <Typography className={classes.section} style={{fontSize: 14, textAlign: 'right', border: 'none', float: 'right', display: 'block', position: 'absolute', right: 0}}>
                        {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: currency || 'USD'}).format(Math.round(((travel_price || 0) + (hotel_price || 0)) / (travelers || 1)))} <br/>
                        <small style={{fontWeight: 500, color: '#333'}}> per person</small>
                      </Typography>
                  </div>
                </Grid>

                {travelers > 1 && <Grid item xs={12}>
                  <Typography className={classes.sectionBooking} style={{textAlign: 'right', float: 'right', display: 'block', }}>
                    {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: currency || 'USD'}).format(Math.ceil(((travel_price || 0) + (hotel_price || 0))))} <br/>
                  </Typography>
                  <Typography className={classes.sectionBooking}>
                    Total for {travelers} travelers
                  </Typography>
                </Grid>}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      :
      <Fragment>{hotel_price}</Fragment>
      )
    )
  }
}

const styles = theme => ({
  header: {
    padding: '0px !important',
  },
  section: {
    fontSize: 18.5,
    fontWeight: 700,
    lineHeight: '18px',
    padding: '15px 20px',

    '& small': {
      color: '#999',
      fontSize: '80%',
    }
  },
  sectionPrice: {
    fontSize: 13,
    fontWeight: 800,
    textAlign: 'right',
    padding: '18px 15px',

    '& small': {
      fontSize: '80%',
      fontWeight: 500,
      color: '#777',
    }
  },
  sectionBooking: {
    fontSize: 13,
    fontWeight: 800,
    lineHeight: '18px',
    padding: '15px 20px 15px 20px',
    borderTop: '1px solid rgba(215, 235, 217, 1)',
    color: 'rgba(95, 143, 99, 0.89)',

    '& small': {
      color: '#999',
      fontSize: '80%',
    },
  },
  skeleton: {
    borderRadius: 10,
  },
  paper: {
    backgroundColor: '#c6e8c940',
    boxShadow: 'none'
  }
})

export default withRouter(withStyles(styles)(TripBookingBox))
