import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Icon,
  Grid,
  TextareaAutosize,
} from '@material-ui/core'
import {
  Alert,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import DatesFilter from '../../components/filters/DatesFilter.js'
import TravelersFilter from '../../components/filters/TravelersFilter.js'
import PlaceField from '../../components/forms/PlaceField.js'
import BudgetFilter from '../../components/filters/BudgetFilter.js'

class PlannerTravel extends Component {
  constructor(props) {
    super(props)

    this.state = props.travel || {
      travelers: {
        adults: 1,
        children: 0,
        infants: 0,
      },
      dates: {
        dates_type: 'cheapest',
        start_date: null,
        end_date: null,
        nights: [2, 4],
      },
      from: null,
      to: null,
      budget: 500,
      currency: props.user && props.user.currency || 'USD',
    }
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  submitRequest = () => {
    this.props.onUpdated({travel: this.state})
  }

  render() {
    const { classes, user } = this.props
    const { travelers, dates, from, to, budget } = this.state

    return (
      <Fragment>
        <Grid item xs={12} md={8} style={{marginBottom: 10, marginTop: 10, maxWidth: 600}}>
          <Grid container className={classes.searchField}>
            <Grid item xs={7} md={8} style={{paddingTop: 5}}>
              <PlaceField
                name="from"
                label="Where from? *"
                autofocus={true}
                placeholder={from && from.length > 0 ? "Where from?" : " "}
                single={true}
                standalone={true}
                defaultValues={[from]}
                onChange={this.handleChange.bind(this)}
                classes={{iconLabel: classes.iconLabel}}
              />
            </Grid>
            <Grid item xs={5} md={4} style={{padding: '5px 5px 5px 5px', borderLeft: '1px solid #ccc'}}>
              <TravelersFilter
                name="travelers"
                adults={travelers && travelers.adults}
                children={travelers && travelers.children}
                infants={travelers && travelers.infants}
                onChange={this.handleChange}
                classes={{iconLabel: classes.iconLabel}}
              >
              </TravelersFilter>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} className={classes.subtitle} style={{textAlign: 'center'}}>
            <ToggleButtonGroup size="small" style={{margin: 'auto'}}>
              <ToggleButton value="planning" disableRipple selected={true} classes={{root: classes.buttonOutlined, selected: classes.buttonOutlinedSelected}} onClick={() => {}}>Enter destination</ToggleButton>
              <ToggleButton value="upcoming" disableRipple disabled selected={false} classes={{root: classes.buttonOutlined, selected: classes.buttonOutlinedSelected}} onClick={() => {}} >Flexible (soon)</ToggleButton>
            </ToggleButtonGroup>
          </Grid> */}
          <Grid container className={classes.searchField}>
            <Grid item xs={7} md={8} style={{paddingTop: 5}}>
              <PlaceField
                name="to"
                label="Where to? *"
                placeholder={to && to.length > 0 ? "Where to?" : " "}
                defaultValues={[to]}
                limitTags={3}
                single={true}
                standalone={true}
                onChange={this.handleChange.bind(this)}
                classes={{iconLabel: classes.iconLabel}}
              />
            </Grid>
            <Grid item xs={5} md={4} style={{padding: '5px 5px 5px 5px', borderLeft: '1px solid #ccc'}}>
              <DatesFilter
                name="dates"
                dates_type = {dates && dates.dates_type}
                start_date = {dates && dates.start_date}
                end_date = {dates && dates.end_date}
                nights={dates && dates.nights}
                onChange={this.handleChange}>
              </DatesFilter>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} className={classes.searchField} style={{padding: '5px 0px 5px 5px'}}>
              <DatesFilter
                name="dates"
                dates_type = {dates && dates.dates_type}
                start_date = {dates && dates.start_date}
                end_date = {dates && dates.end_date}
                nights={dates && dates.nights}
                onChange={this.handleChange}>
              </DatesFilter>
          </Grid> */}
          <Grid item xs={12} className={classes.searchField} style={{paddingTop: 50, textAlign: 'center', border: 'none', fontWeight: 500, color: '#777', marginBottom: 20}}>
            Set your travel style (optional)
          </Grid>
          <Grid item xs={12} className={classes.searchField} style={{paddingTop: 5, border: 'none', backgroundColor: '#fafafa'}}>
            <BudgetFilter name="budget" max={5000} label={'Budget per person: '} budget={this.state.budget} currency={this.state.currency} user={this.props.user} onChange={this.handleChange.bind(this)}/>
          </Grid>
          {/* <Grid item xs={12} className={classes.searchField} style={{paddingTop: 5, border: 'none', backgroundColor: '#fafafa'}}>
            <div style={{padding: '6px 5px 5px 5px'}}>
              <div className={classes.iconLabel}>
                <Icon className={`material-icons-outlined notranslate ${classes.icon}`}>attractions</Icon>
                What would you like to do there ?
              </div>
              <TextareaAutosize
                id="trip_description"
                aria-label="trip_description textarea"
                placeholder="E.g. Visit top attractions and unique coffee"
                variant="outlined"
                name="trip_description"
                minRows={3}
                className={classes.textfieldArea}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.searchField} style={{paddingTop: 5, border: 'none', backgroundColor: '#fafafa', marginBottom: 100}}>
            <div style={{padding: '6px 5px 5px 5px'}}>
              <div className={classes.iconLabel}>
                <Icon className={`material-icons-outlined notranslate ${classes.icon}`}>thumb_down_alt</Icon>
                I'm not interested in
              </div>
              <TextareaAutosize
                id="trip_description"
                aria-label="trip_description textarea"
                placeholder="E.g. Sport, Museums, Nightlife"
                variant="outlined"
                name="trip_description"
                minRows={3}
                className={classes.textfieldArea}
              />
            </div>
          </Grid> */}

        </Grid>

        <Grid item xs={12} md={8} className={classes.bottomBar}>
          <Button disabled={!(this.state.from) || !(this.state.to) || this.state.isSubmitting} onClick={this.submitRequest} variant="contained" color="primary" size="large" className={classes.buttonBook}>
            {this.state.isSubmitting ?
              <Fragment>Sending...</Fragment>
              :
              <Fragment>Continue<Icon className="material-icons-outlined notranslate" style={{marginLeft: 10, marginBottom: -7}}>east</Icon></Fragment>
            }
          </Button>
        </Grid>
      </Fragment>
    )
  }
}

const styles = theme => ({
  searchField: {
    border: '1px solid #ccc',
    borderRadius: 20,
    marginBottom: 10,
  },
  icon: {
    marginRight: 5,
    fontSize: 15,
    fontWeight: 600,
    marginTop: -3,
    padding: '0px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  iconLabel: {
    color: '#333',
    fontSize: 13,
    fontWeight: 700,
    marginBottom: -4,
    marginLeft: 5,
  },
  textfieldArea: {
    width: 'calc(100% - 30px)',
    textAlign: 'left',
    backgroundColor: '#fff',
    borderRadius: 10,
    margin: '15px 10px 0px 5px',
    fontSize: 14,
    fontWeight: 600,
    padding: '11px 10px',
    border: '1px solid #ddd',
    fontFamily: 'inherit',
    minHeight: 20,
    maxWidth: '100%',
    minWidth: '88%',

    '&:focus': {
      outline: 0,
    },
    '& fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
    '&:hover fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
    '&::placeholder': {
      color: '#999',
    },
  },
  h3: {},
  gridFilters: {},
  headlinePaper: {},
  headline: {},
  subtitle: {},
  listItemIcon: {},
  listItemText: {},
  listItemTextSecondary: {},
  listItemTextPrice: {},
  listItemSelected: {},
  listItem: {},
  buttonBook: {},
  bottomBar: {},
  buttonOutlined: {},
  buttonOutlinedSelected: {},
})

export default withRouter(withStyles(styles)(PlannerTravel))
