import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Box,
  Button,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  withStyles
} from '@material-ui/core';
import {
  TimelineSeparator,
  TimelineDot,
} from '@material-ui/lab'
import { ITINERARY_ITEM_CATEGORIES }  from '../../utils/lists'
import PlannerQuiz from '../../containers/planner/PlannerQuiz'


const ACTIONS = [
  {
    icon: 'format_list_bulleted',
    name: 'Generate travel guide',
    value: 'travel_guide'
  },
]

class ItineraryInput extends Component {

  constructor(props) {
    super(props)
    this.state = {
      showQuiz: false, // props.user.is_registered ? false : true,

    };
  }

  generate = (category = 'travel_guide') => {
    if (this.props.user && this.props.user.travel_preference) {
      this.props.onGenerateItem()
    }
  }

  handleTravelGuide = (item) => {
    this.props.onGenerateItem('travel_guide', {travel_preference: item.preferences})
  }

  render() {
    const {
      classes
    } = this.props

    return (
      <Box id="input" className={`${classes.itineraryRail}`} style={{paddingTop: 50}}>
        <TimelineSeparator>
          <TimelineDot classes={{root: classes.itineraryIconRoot}}>
            <Icon className={`${classes.icon} material-icons-outlined notranslate`}>auto_awesome</Icon>
          </TimelineDot>
        </TimelineSeparator>
        {/* <Box className={`${classes.itineraryRoot}`}>
          <Box className={classes.itineraryTitle}>
            Ask AI to create a personalized guide...
          </Box>
        </Box> */}
        <Box className={`${classes.itineraryCard} ${classes.itineraryRoot}`}>
          Where would you like to go ?
          <TextField
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true, classes: {root: classes.label} }}
            InputProps={{ classes: {input: classes.textField} }}
            variant="outlined"
            size="small"
            name="prompt"
            // value={this.state.title}
            // onChange={this.handleChange}
          />
          {/* temporary button to generate guide */}
          {!this.state.showQuiz && <Box className={classes.itineraryCardTitle} style={{textAlign: 'center'}}>
            <Button size="small" style={{margin: 'auto'}} className={classes.buttonBook} onClick={this.generate}>
              <Icon className={`material-icons-outlined notranslate`} style={{marginRight: 5}}>auto_fix_high</Icon> Generate
            </Button>
          </Box>}

          {/* <List className={classes.menu}>
            {ACTIONS.map((category, i) => {
              return <ListItem button key={category.name} onClick={() => this.generateGuide(category.value)}>
                <ListItemIcon><Icon className={`material-icons-outlined notranslate ${classes.listItemIcon}`} style={{color: '#02c39a'}}>{category.icon}</Icon></ListItemIcon>
                <ListItemText primary={category.name} classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}} />
              </ListItem>
            })}
          </List> */}

          {/* {this.state.showQuiz && <PlannerQuiz onUpdated={this.handleTravelGuide}/>} */}
        </Box>
      </Box>
    )
  }
}

const styles = theme => ({
  backgroundImage: {
    backgroundImage: 'url(/discover-weekly-background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  itineraryRoot: {
    margin: '10px 0px',
    marginLeft: "65px !important",
    width: 'auto',
    padding: 5,
    position: 'relative',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '25px',

    [theme.breakpoints.down('xs')]: {
      margin: '10px 0px',
      marginLeft: "0px !important",
    },
  },
  itineraryCard: {
    margin: '10px 0px',
    // boxShadow: '0 0px 10px 0 rgb(0 0 0 / 5%)',
    border: '1px solid #eee',
    width: 'auto',
    padding: 15,
    position: 'relative',
    zIndex: 2,
    borderRadius: '15px !important',
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 500,

    [theme.breakpoints.down('xs')]: {
      margin: '10px 0px',
      padding: '10px',
    },
  },
  itineraryCardTitle: {
    fontSize: '15px',
    fontWeight: 700,
    padding: '0px 0px 0px',
  },
  itineraryTitle: {
    fontSize: '15px',
    fontWeight: 700,
    padding: '0px 0px 0px',

    [theme.breakpoints.down('xs')]: {
      minHeight: 35,
      padding: '15px 0px 0px 55px',
    },
  },
  itineraryRail: {
    textAlign: 'left',

    '&::before': {
      position: 'absolute',
      zIndex: 0,
      left: 31,
      width: 2,
      content: '""',
      top: 30,
      height: 'calc(100% - 90px)',
      background: '#f2f2f2',
    },
  },
  itineraryIconRoot: {
    float: 'left',
    zIndex: 3,
    position: 'absolute',
    left: 9,
    display: 'block',
    marginTop: 10,
    color: '#333',
    backgroundColor: '#c6e8c9',
    boxShadow: 'none',

    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
  icon: {
    verticalAlign: 'middle !important',
    padding: '5px 5px 5px 5px !important',

    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  buttonBook: {
    zIndex: 2,
    color: '#fff',
    margin: '0px 0px 0px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    borderRadius: 25,
    fontWeight: 800,
    padding: '10px 15px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
});

export default withStyles(styles)(withRouter(ItineraryInput));
