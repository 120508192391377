import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import SelectorOption from '../../components/chat/SelectorOption'
import SliderOption from '../../components/chat/SliderOption'
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import ChatBot from 'react-simple-chatbot'
import { ThemeProvider } from 'styled-components'
import { THINGS_TO_DO, CUISINES } from '../../utils/lists'

const theme = {
  background: '#fff',
  fontFamily: 'Manrope,Helvetica Neue,Helvetica,-apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif',
  headerBgColor: '#02c39a',
  headerFontColor: '#333',
  headerFontSize: '15px',
  botBubbleColor: 'rgb(250, 250, 250)',
  botFontColor: '#333',
  botFontSize: '14px',
  userBubbleColor: 'rgba(7, 195, 153, 0.26)',
  userFontColor: '#000',
};

class PlannerQuiz extends Component {
  constructor(props) {
    super(props)
    this.state = {
      preferences: props.preferences || {},
    }
  }

  submitRequest = ({ steps, values }) => {
    const test = values.reduce(((r, c) => Object.assign(r, c)), {})
    const preferences = {
      food_cuisine: test.food_cuisine.split(','),
      things_to_do: test.things_to_do.split(','),
      vibe_adventurous: test.vibe_adventurous,
      vibe_chill_relaxing: test.vibe_chill_relaxing,
      vibe_curious: test.vibe_curious,
      vibe_fast_paced: test.vibe_fast_paced,
      vibe_luxurious: test.vibe_luxurious,
      vibe_off_the_beaten_path: test.vibe_off_the_beaten_path,
      vibe_popular: test.vibe_popular,
      vibe_romantic: test.vibe_romantic,
    }

    this.setState({preferences: preferences}, this.props.onUpdated({preferences: preferences}))
  }

  getUser = () => {
    return this.props.user
  }

  render() {
    const { classes } = this.props
    const { preferences } = this.state
    const steps = [
      // {
      //   id: '0',
      //   message: "Discover destinations that match your travel style",
      //   trigger: '1',
      //   hideInput: true,
      // },
      // {
      //   id: '0',
      //   message: "Few questions help us to show you better suggestions",
      //   trigger: '2',
      //   hideInput: true,
      // },
      {
        id: '2',
        message: "What would you like to do on your trip?",
        trigger: '3',
        hideInput: true,
      },
      {
        id: '3',
        component: <SelectorOption name={"things_to_do"} selected={preferences.things_to_do} list={THINGS_TO_DO.sort(() => Math.random() - 0.5)} showImages={false} multiple={true} maxSelected={THINGS_TO_DO.length}/>,
        waitAction: true,
        delay: 0,
        trigger: '6',
        hideInput: true,
      },
      {
        id: '6',
        message: "What cuisine would you like to try ?",
        trigger: '7',
        hideInput: true,
      },
      {
        id: '7',
        component: <SelectorOption name={"food_cuisine"} selected={preferences.food_cuisine} list={CUISINES.sort(() => Math.random() - 0.5)} multiple={true} showImages={true} maxSelected={5}/>,
        waitAction: true,
        delay: 0,
        trigger: '8',
        hideInput: true,
      },
      {
        id: '8',
        message: "What vibe are you looking for on a trip? (1/4)",
        trigger: '10',
        hideInput: true,
      },
      // {
      //   id: '9',
      //   message: "More adventurous or more relaxing? ",
      //   trigger: '10',
      //   hideInput: true,
      // },
      {
        id: '10',
        component: <SliderOption leftLabel="Adventurous" rightLabel="Relaxing" defaultValue={0} selected={preferences.vibe_adventurous ? (-preferences.vibe_adventurous + preferences.vibe_romantic) : 0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
          {vibe_adventurous: 1.0, vibe_romantic: 0.0},
          {vibe_adventurous: 0.75, vibe_romantic: 0.25},
          {vibe_adventurous: 0.0, vibe_romantic: 0.0},
          {vibe_adventurous: 0.25, vibe_romantic: 0.75},
          {vibe_adventurous: 0.0, vibe_romantic: 1.0},
        ]}/>,
        waitAction: true,
        delay: 0,
        trigger: '11',
        hideInput: true,
      },
      {
        id: '11',
        message: "Slow-paced or fast-paced? (2/4)",
        trigger: '12',
        hideInput: true,
      },
      {
        id: '12',
        component: <SliderOption leftLabel="Slow-paced" rightLabel="Fast-paced" defaultValue={0} selected={preferences.vibe_chill_relaxing ? (-preferences.vibe_chill_relaxing + preferences.vibe_fast_paced) : 0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
          {vibe_chill_relaxing: 1.0, vibe_fast_paced: 0.0},
          {vibe_chill_relaxing: 0.75, vibe_fast_paced: 0.25},
          {vibe_chill_relaxing: 0.0, vibe_fast_paced: 0.0},
          {vibe_chill_relaxing: 0.25, vibe_fast_paced: 0.75},
          {vibe_chill_relaxing: 0.0, vibe_fast_paced: 1.0},
        ]}/>,
        waitAction: true,
        delay: 0,
        trigger: '13',
        hideInput: true,
      },
      {
        id: '13',
        message: "Do you prefer higher comfort or lower budget? (3/4)",
        trigger: '14',
        hideInput: true,
      },
      {
        id: '14',
        component: <SliderOption leftLabel="Comfort" rightLabel="Budget" defaultValue={0} selected={preferences.vibe_luxurious ? (-preferences.vibe_luxurious + preferences.vibe_curious) : 0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
          {vibe_luxurious: 1.0, vibe_curious: 0.0},
          {vibe_luxurious: 0.75, vibe_curious: 0.25},
          {vibe_luxurious: 0.0, vibe_curious: 0.0},
          {vibe_luxurious: 0.25, vibe_curious: 0.75},
          {vibe_luxurious: 0.0, vibe_curious: 1.0},
        ]}/>,
        waitAction: true,
        delay: 0,
        trigger: '15',
        hideInput: true,
      },
      {
        id: '15',
        message: "More popular or more off the beaten path? (4/4)",
        trigger: '16',
        hideInput: true,
      },
      {
        id: '16',
        component: <SliderOption leftLabel="Popular" rightLabel="Off the beaten path" defaultValue={0} selected={preferences.vibe_popular ? (-preferences.vibe_popular + preferences.vibe_off_the_beaten_path) : 0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
          {vibe_popular: 1.0, vibe_off_the_beaten_path: 0.0},
          {vibe_popular: 0.75, vibe_off_the_beaten_path: 0.25},
          {vibe_popular: 0.0, vibe_off_the_beaten_path: 0.0},
          {vibe_popular: 0.25, vibe_off_the_beaten_path: 0.75},
          {vibe_popular: 0.0, vibe_off_the_beaten_path: 1.0},
        ]}/>,
        waitAction: true,
        delay: 0,
        trigger: '20',
        hideInput: true,
      },
      // {
      //   id: '20',
      //   message: 'We\'re analysing the best matches for your responses...',
      //   delay: 0,
      //   trigger: '21',
      //   hideInput: true,
      // },
      {
        id: '20',
        message: 'That\'s all',
        // trigger: 'end',
        delay: 300,
        hideInput: true,
        end: true,
      },
      // {
      //   id: 'end',
      //   delay: 0,
      //   component: <SavePreferencesOption getUser={this.getUser}/>,
      //   hideInput: true,
      //   waitAction: true,
      //   end: true,
      // },
    ];

    return (
      <Fragment>
        <div className={classes.chatContainer}>
          {/* <Grid item xs={12} sm={12} md={12} className={classes.gridFilters}> */}
            {/* <Grid item xs={12} className={classes.showcaseDestinations}> */}
              <ThemeProvider theme={theme}>
                <ChatBot
                  handleEnd={this.submitRequest}
                  steps={steps}
                  botAvatar="/apple-touch-icon.png"
                  enableSmoothScroll={true}
                  hideHeader={true}
                  hideUserAvatar={true}
                  hideSubmitButton={true}
                  botDelay={1000}
                  userDelay={300}
                  width={'100%'}
                  style={{boxShadow: 'none', height: '100%'}}
                  avatarStyle={{boxShadow: 'none', alignItems: 'flex-start'}}
                  contentStyle={{height: (isWidthUp('sm', this.props.width) ? (isWidthUp('md', this.props.width) ? 'calc(100vh - 255px)' : 'calc(100vh - 255px)') : 'calc(100vh - 182px)')}}
                  bubbleStyle={{fontSize: '15px', fontWeight: 700, boxShadow: 'none', background: 'none', maxWidth: '80%'}}
                  bubbleOptionStyle={{fontFamily: theme.fontFamily, fontSize: '13px', fontWeight: 600, backgroundColor: 'rgba(7, 195, 153, 0.26)'}}
                  customStyle={{boxShadow: 'none', padding: '16px 0px', borderRadius: 0, borderBottom: '1px solid #ddd'}}
                />
              </ThemeProvider>
            {/* </Grid> */}
          {/* </Grid> */}
        </div>
      </Fragment>
    )
  }
}

const styles = theme => ({
  showcaseDestinations: {
    margin: '10px 0px 0px 0px',
  },
  chatContainer: {
    padding: '0px',
    paddingTop: 0,
    minHeight: 'calc(100vh - 455px)',
    backgroundColor: '#fff',
    width: 'inherit',
    borderTop: '1px solid #ddd',
    maxWidth: 600,
  },
  h3: {},
  gridFilters: {},
  headlinePaper: {},
  headline: {},
  subtitle: {},
  listItemIcon: {},
  listItemText: {},
  listItemTextSecondary: {},
  listItemTextPrice: {},
  listItemSelected: {},
  listItem: {},
  buttonBook: {},
  bottomBar: {},
  buttonOutlined: {},
  buttonOutlinedSelected: {},
})

export default withRouter(withStyles(styles)(withWidth()(PlannerQuiz)))
