import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionActions,
  AccordionSummary,
  Button,
  Box,
  Grid,
  Typography,
 } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { count_stops } from '../../../utils/helpers';
import FlightLegDetail from './FlightLegDetail';
import FlightRow from './FlightRow';

const cabinClass = (class_sign) => {
  if (class_sign === 'M') {
    return "Economy";
  } else if (class_sign === 'W') {
    return "Economy Premium";
  } else if (class_sign === 'C') {
    return "Business";
  } else if (class_sign === 'F') {
    return "First class";
  } else {
    return "Economy";
  }
}

class FlightCard extends Component {

  render() {
    const { classes } = this.props;
    const depart_route = count_stops(this.props.flight.route, 0);
    const return_route = count_stops(this.props.flight.route, 1);
    const depart_stops = depart_route.length - 1;
    const return_stops = return_route.length - 1;

    return (
      <Fragment>
      <Accordion
        classes={{
          expanded: classes.expanded,
          root: classes.root,
        }}
        className={(this.props.selected_flight_id === this.props.flight.id ? "selected" : "")}
        elevation={0}
        >

        <AccordionSummary classes={{root: classes.summary, expandIcon: classes.expandIcon}} className="expansion_panel" expandIcon={<ExpandMoreIcon/>}>
          <Grid container spacing={2}>
            {/* Departure flight */}
            <FlightRow departure_airline={depart_route[0].airline}
                       departure_time={depart_route[0].local_departure}
                       arrival_time={depart_route.slice(-1)[0].local_arrival}
                       flight_duration={(this.props.flight.duration && this.props.flight.duration.departure && `${Math.trunc(this.props.flight.duration.departure / 3600)}h ${Math.trunc((this.props.flight.duration.departure % 3600) / 60)}m`)}
                       flight_stops={depart_stops}
                       flight_city_from={this.props.flight.cityFrom}
                       flight_city_to={this.props.flight.cityTo}
                       flight_cabin_class={this.props.flight.cabin_class}
                       divider={this.props.flight.nightsInDest ? true : false}>
            </FlightRow>
            {/* Arrival flight */}
            {return_route && <FlightRow departure_airline={return_route.slice(-1)[0].airline}
                        departure_time={return_route[0].local_departure}
                        arrival_time={return_route.slice(-1)[0].local_arrival}
                        flight_duration={(this.props.flight.duration && this.props.flight.duration.return && `${Math.trunc(this.props.flight.duration.return / 3600)}h ${Math.trunc((this.props.flight.duration.return % 3600) / 60)}m`)}
                        flight_stops={return_stops}
                        flight_city_from={return_route[0].cityFrom}
                        flight_city_to={return_route.slice(-1)[0].cityTo}
                        flight_cabin_class={this.props.flight.cabin_class}
                        divider={false}>
            </FlightRow>}
            {/* Price details */}
            {this.props.travelers && <Grid item xs={12} className="flight-price">
              <Button onClick={this.props.onSelected && this.props.onSelected.bind(this, this.props.flight)} variant="contained" color="primary" size="large" className={classes.buttonBook} style={{float: 'right'}} rel="noopener noreferrer">
                Select
              </Button>
              <Typography className={classes.heading}>
                <b>{new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: this.props.currency || 'USD'}).format(Math.round(this.props.flight.price / this.props.travelers))}</b>
                <small style={{fontWeight: 500, fontSize: 12, color: '#777'}}> / person, {this.props.flight.nightsInDest} nights</small>
              </Typography>
            </Grid>}
          </Grid>

        </AccordionSummary>

        <AccordionDetails className={classes.details} classes={{root: classes.detailsRoot}}>
          <Grid className="flight-details" container>
            {/* Depart flight */}
            <Grid item xs={12} sm={6} md={6} className="depart">
              <div className="directions">
                Depart &bull; {moment.utc(depart_route[0].local_departure).format('ddd')}, {moment.utc(depart_route[0].local_departure).format('MMM DD')}
                <Box className={classes.cabinClass}>{this.props.flight.cabin_class ? cabinClass(this.props.flight.cabin_class) : ''}</Box>
              </div>

              {depart_route.map((route, index) => {
                if (route.return === 0) {
                  let layover = depart_route[index + 1] && depart_route[index + 1].return === 0 ? Math.floor((moment(depart_route[index + 1].local_departure).diff(moment(route.local_arrival), 'minutes') % 1440) / 60) + 'h ' + Math.floor((moment(depart_route[index + 1].local_departure).diff(moment(route.local_arrival), 'minutes') % 1440) % 60) + 'm ' : null;

                  return <FlightLegDetail key={index} layover={layover} route={route} flight_cabin_class={this.props.flight.cabin_class}></FlightLegDetail>
                } else {
                  return null;
                }
              })}
            </Grid>
            {/* Return flight */}
            {return_route && <Grid item xs={12} sm={6} md={6} className="return">
              <div className="directions">
                Return &bull; {moment.utc(return_route[0].local_departure).format('ddd')}, {moment.utc(return_route[0].local_departure).format('MMM DD')}
                <Box className={classes.cabinClass}>{this.props.flight.cabin_class ? cabinClass(this.props.flight.cabin_class) : ''}</Box>
              </div>

              {return_route.map((route, index) => {
                if (route.return === 1) {
                  let layover = return_route[index + 1] && return_route[index + 1].return === 1 ? Math.floor((moment(return_route[index + 1].local_departure).diff(moment(route.local_arrival), 'minutes') % 1440) / 60) + 'h ' + Math.floor((moment(return_route[index + 1].local_departure).diff(moment(route.local_arrival), 'minutes') % 1440) % 60) + 'm ' : null;

                  return <FlightLegDetail key={index} layover={layover} route={route} flight_cabin_class={this.props.flight.cabin_class}></FlightLegDetail>
                } else {
                  return null;
                }
              })}
            </Grid>}
          </Grid>
        </AccordionDetails>
        {/* {this.props.onSelected &&
          <AccordionActions>
            <Button
              size="large"
              classes={{
                root: classes.button,
              }}
              variant="contained"
              color="primary"
              name="Flight details"
              onClick={this.props.onSelected.bind(this, this.props.flight)}
            >
              Select flight
            </Button>
          </AccordionActions>
        } */}
      </Accordion>
    </Fragment>
    )
  }
}

const styles = theme => ({
  root: {},
  heading: {
    fontSize: theme.typography.pxToRem(18),
    padding: '5px 0px',
  },
  secondaryHeading: {
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    color: 'rgba(0, 0, 0, 0.44)',
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  cabinClass: {
    fontWeight: 700,
    color: '#02c39a',
    marginTop: 5,
  },
  details: {
    alignItems: 'center',
    backgroundColor: '#fefefe',
    borderBottomRightRadius: 25,
    borderBottomLeftRadius: 25,
    borderTop: '1px solid #eee',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  expanded: {
    margin: '8px 0px 0px 0px !important'
  },
  expandIcon: {
    float: 'right',
    position: 'absolute',
    top: '10px',
    right: 5,
  },
  button: {
    width: '98%',
    boxShadow: 'none',
    margin: '0px auto',
    backgroundColor: '#00a1ff',
  },
  detailsRoot: {
    padding: 0,
  },
  buttonBook: {
    // float: 'right',
    color: '#fff',
    margin: '0px 5px 0px 20px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  summary: {
    // width: 'calc(100% - 32px)',
    flexDirection: "row-reverse",
    padding: '0px !important',
  },
});

export default withStyles(styles)(FlightCard)
