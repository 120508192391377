import React, { Component, Fragment } from 'react'
import ReactMarkdown from 'react-markdown'
import ReactPixel from 'react-facebook-pixel'
import moment from 'moment'
import {
  Box,
  Button,
  Badge,
  CardMedia,
  CircularProgress,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  withStyles
} from '@material-ui/core';
import {
  TimelineSeparator,
  TimelineDot,
} from '@material-ui/lab'
import HotelCard from './hotel/HotelCard'
import FlightCard from './flight/FlightCard'
import Cookies from 'universal-cookie'
import { config } from '../../config'
import { ITINERARY_ITEM_CATEGORIES }  from '../../utils/lists'
import { Link } from 'react-router-dom';

const cookies = new Cookies();

class ItineraryItem extends Component {

  constructor(props) {
    super(props)
    this.state = {
      id: props.item.id,
      isSaving: false,
      isDeleting: false,
      day: (props.item && props.item.day ? props.item.day : '' ),
      url: (props.item.item ? props.item.item.url : '' ),
      title: (props.item && props.item.title ? props.item.title : '' ),
      description: (props.item && props.item.description ? props.item.description : '' ),
      address: (props.item && props.item.address? props.item.address : '' ),
      notes: (props.item && props.item.notes ? props.item.notes : '' ),
      order: (props.item && props.item.order ? props.item.order : '' ),
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.item.id !== state.id) {
      return {
          id: props.item.id,
          day: (props.item && props.item.day ? props.item.day : '' ),
          order: (props.item && props.item.order ? props.item.order : '' ),
          url: (props.item.item ? props.item.item.url : '' ),
          title: (props.item && props.item.title ? props.item.title : '' ),
          description: (props.item && props.item.description ? props.item.description : '' ),
          address: (props.item && props.item.address? props.item.address : '' ),
          notes: (props.item && props.item.notes ? props.item.notes : '' ),
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  renderItineraryIcon(category) {
    switch(category) {
      case 'travel':
        return 'flight_takeoff';
      case 'restaurant':
        return 'restaurant';
      case 'accommodation':
        return 'hotel';
      case 'event':
        return 'event';
      case 'activity':
        return 'local_activity';
      case 'car_rental':
        return 'car_rental';
      case 'note':
        return 'sticky_note_2';
      case 'place':
        return 'place';
      case 'thing_to_do':
        return 'photo_camera';
      case 'ai':
        return 'auto_awesome';
      default:
        return 'sticky_note_2';
    }
  }

  getDates = (start_date, end_date) => {
    let dateArr = [];
    let fromDate = new Date(moment(start_date).format('YYYY-MM-DD'));
    let endDate = new Date(moment(end_date).format('YYYY-MM-DD'));

    while(fromDate <= endDate){
      dateArr.push(moment(fromDate).format('YYYY-MM-DD'));
      let newDate = fromDate.setDate(fromDate.getDate() + 1);
      fromDate = new Date(newDate);
    }

    return dateArr;
  }

  handleClear = () => {
    this.setState({
      id: null,
      isSaving: false,
      isDeleting: false,
      category: "",
      url: "",
      title: "",
      description: "",
      notes: "",
      day: "",
      order: "",
    })
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  handleCancel = (item) => {
    this.handleClear()
    this.props.onCancel(item)
  }

  handleSave = () => {
    this.setState({isSaving: true})
    const user_jwt_token = cookies.get('jwt')

    if (user_jwt_token !== undefined) {
      fetch(config.api.TRAVEL_URL + '/itinerary_items' + ((this.props.item && this.props.item.id) ? `/${this.props.item.id}` :''), {
        method: ((this.props.item && this.props.item.id) ? 'PUT' : 'POST'),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user_jwt_token}`,
        },
        dataType: 'json',
        // We convert the React state to JSON and send it as the POST body
        body: JSON.stringify({
          category: this.props.item.category,
          day: this.state.day,
          order: this.state.order,
          url: this.state.url,
          title: this.state.title,
          description: this.state.description,
          notes: this.state.notes,
          itinerary_type: this.props.itineraryType,
          itinerary_id: this.props.itineraryId,
        })
      })
      .then(response => response.json())
      .then(data => {
        this.setState({isSaving: false})

        if (data.itinerary) {
          this.props.onSavedItinerary(data.itinerary)
          this.handleClear()
        }
      })

    } else {
      this.openSigninModal()
    }
  }

  handleDelete = () => {
    this.setState({isDeleting: true})
    const user_jwt_token = cookies.get('jwt')

    if (user_jwt_token !== undefined) {
      fetch(config.api.TRAVEL_URL + '/itinerary_items' + ((this.props.item && this.props.item.id) ? `/${this.props.item.id}` :''), {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user_jwt_token}`,
        },
        dataType: 'json',
        body: JSON.stringify({
          itinerary_type: this.props.itineraryType,
          itinerary_id: this.props.itineraryId,
        })
      })
      .then(response => response.json())
      .then(data => {
        this.setState({isDeleting: false})

        if (data.itinerary) {
          this.props.onSavedItinerary(data.itinerary)
          this.handleClear()
        }
      })
    }
  }

  handleBookingLink = (id, category, price, currency, url, name) => {
    let affiliate_rate = (category === "hotel" ? 0.04 : 0.03)

    window.gtag('event', 'purchase', {
      transaction_id: `${category}_${id}`,
      value: Math.round(price * affiliate_rate),
      currency: currency,
      items: [{
        item_id: id,
        item_name: name,
        item_brand: (new URL(url)).hostname,
        item_category: category,
        price: Math.round(price * affiliate_rate),
      }]
    });
    ReactPixel.track('Purchase', {
      currency: currency,
      value: Math.round(price * affiliate_rate)
    })
  }

  render() {
    const {
      classes,
      isEditing,
      item,
      destination,
      search_params,
      user,
      editable,
    } = this.props
    const { currency, adults, children } = search_params
    const { start_date, end_date } = destination

    return (
      ((!isEditing && item.id) ? <Box id={item.id} className={`${classes.itineraryRail}`}>
        <TimelineSeparator>
          {item.category !== "note" && <TimelineDot classes={{root: classes.itineraryIconRoot}}>
            {item.isLoading && <CircularProgress style={{fontWeight: '500', margin: 'auto', position: 'absolute', height: 38, width: 38, top: -2, left: -2}}/>}
            {item.category !== "place" ?
              <Icon className={`${classes.icon} material-icons-outlined notranslate`}>{this.renderItineraryIcon(item.category)}</Icon>
              :
              <Box className={classes.iconNumber}>{item.order+1}</Box>
            }
          </TimelineDot>}
        </TimelineSeparator>
        <Box className={item.category !== "note" ? classes.itineraryRoot : classes.itineraryRootNote}>
          {(user && user.is_registered && editable) && <IconButton onClick={this.props.onEditItem.bind(this, item)} size="small" style={{float: 'right', margin: '-5px -5px 5px 5px', padding: 7}}><Icon className={`${''} material-icons-outlined notranslate`} style={{fontSize: 18}}>edit</Icon></IconButton>}
          {item.title && item.title !== "" && <Fragment>
            {(item.title || (item.item && item.item.title)) && <Box className={classes.itineraryTitle}>
              {item.title || item.item.title}
              {item.time && <small style={{fontWeight: 500, opacity: 0.9}}><br/>{item.category === "place" && <Icon className={`material-icons-outlined notranslate`} style={{fontSize: 16, margin: "0px 3px -3px 0px", fontWeight: 900}}>place</Icon>}{item.time}</small>}
            </Box>}
          </Fragment>}
          {item.notes && <Box className={item.category !== "note" ? classes.itineraryNotes : classes.itineraryOnlyNotes}>
            {item.category !== "note" && <Icon className={`${''} material-icons-outlined notranslate`} style={{fontSize: 20, paddingRight: 10, verticalAlign: 'middle'}}>sticky_note_2</Icon>}
            {item.notes}
          </Box>}
          {(item.description || (item.item && item.item.description)) && item.category !== "note" && item.category !== "program" && <Box className={classes.itineraryDescription}>
            {item.description || (item.item && item.item.description)}
          </Box>}

          {(item.category == "program" && item.description) &&
            <Box className={classes.itineraryTextOnly}><ReactMarkdown>{item.description}</ReactMarkdown></Box>
          }

          {(item.item || item.category == "travel" || item.category == "accommodation") && !item.isLoading && <Box className={item ? classes.itineraryCard : ''}>
            {item.images && item.images.length > 0 && <Box>
              <CardMedia
                component="img"
                image={item.images[0]}
                style={{display: 'block', minWidth: '100%', height: 250, borderRadius: 15}}
              />
            </Box>}
            {/* {(item.item && item.item.description) && <Box>{item.item.description}</Box>} */}

            {(item.category == "travel" && item.raw_details) &&
              <div className='flights-container'><FlightCard flight={item.raw_details} currency={currency} classes={{root: classes.root}} readonly/></div>
            }

            {(item.category == "accommodation" && item.raw_details) &&
              <div className='hotels-container'><HotelCard hotel={item.raw_details} adults={adults} children={children} startDate={start_date} endDate={end_date} currency={currency} search_params={search_params} destination={destination} classes={{root: classes.root}} readonly smallImages={true} compact={false} /></div>
            }

            {(item.item && item.item.url) && <Box style={{color: '#999', padding: '10px 0px', borderTop: '0px solid #f2f2f2'}}>
              <Button size="small"
                component="a"
                className={classes.itineraryLinkBtn}
                rel="noopener noreferrer"
                target="_blank"
                href={item.item.url}
              >
                {'Details'}
                <Icon style={{fontSize: 20, paddingLeft: 5}}>open_in_new</Icon>
              </Button>
              <Box style={{marginTop: 10}}>{item.item && item.item.host}</Box>
            </Box>}

            <Box style={{color: '#333', padding: '5px 0px 5px 5px', marginTop: 10, borderTop: '1px solid #f2f2f2'}}>
              {item.booking_url && item.booking_url !== null && <Button variant="contained" color="primary" size="small" className={classes.buttonBook} style={{boxShadow: 'none', float: 'right', padding: '6px 19px', margin: '1px 0px 0px 5px', width: 'auto'}}
                rel="noopener noreferrer"
                component="a"
                target="_blank"
                href={item.booking_url}
                onClick={() => this.handleBookingLink(item.id, item.category, item.booking_total_price, item.booking_currency, item.booking_url, item.name)}
              >
                Reserve
                <Icon style={{fontSize: 15, paddingLeft: 5}}>open_in_new</Icon>
              </Button>}
              {/* <Box style={{display: 'block', float: 'right', paddingTop: 0, marginTop: -1}}>
                <Button size="small" className={classes.itineraryLinkBtn}>
                  <Icon style={{fontSize: 20, paddingRight: 5}}>add_circle_outline</Icon>
                  More options
                </Button>
              </Box> */}
              <Box style={{display: 'block', paddingTop: 5, fontWeight: 700, fontSize: 15, textAlign: 'left', minHeight: 22}}>
                {(item.booking_total_price && item.booking_total_price > 0) ?
                  <Fragment>
                    {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: (item.booking_currency || 'USD')}).format(Math.ceil(item.booking_total_price / (adults + children)))}<small style={{fontWeight: 600, paddingLeft: 3}}> / person</small>
                  </Fragment>
                : <Fragment></Fragment>}
              </Box>
            </Box>

          </Box>}
        </Box>
      </Box>
      :
      <Box className={`${classes.itineraryRail}`}>
        <TimelineSeparator>
          <TimelineDot classes={{root: classes.itineraryIconRoot}}>
            <Icon className={`${classes.icon} material-icons-outlined notranslate`}>{this.renderItineraryIcon(item.category)}</Icon>
          </TimelineDot>
        </TimelineSeparator>
        <Box className={`${classes.itineraryCard} ${classes.itineraryRoot}`}>
          {!isEditing && <Box className={classes.itineraryTitle}>
            Add {item.category !== "program" && ITINERARY_ITEM_CATEGORIES.filter((category) => {return category.value === item.category}).shift().name}
          </Box>}
          <Box className={classes.itineraryDescription}>
            <FormControl margin="normal">
              <InputLabel shrink id="day-label" className={classes.selectLabel} variant="outlined" size="small" >Day</InputLabel>
              <Select
                id="day-select"
                labelId="day-label"
                style={{width: '140px', borderRadius: 25,}}
                classes={{select: classes.select}}
                variant="outlined"
                size="small"
                name="day"
                value={this.state.day}
                onChange={this.handleChange}
              >
                {this.getDates(destination.start_date, destination.end_date).map((date, i) => {
                  return <MenuItem key={i} value={i+1} className={classes.itinerarySelectOption}>
                    Day {i+1}: {moment(date).format('MMM DD')}{', '}{moment(date).format('ddd')}{''}
                  </MenuItem>
                })}
              </Select>
            </FormControl>

            <TextField
              label="Order"
              name="order"
              margin="normal"
              InputLabelProps={{ shrink: true, classes: {root: classes.label} }}
              InputProps={{ classes: {input: classes.textField} }}
              style={{width: '140px', marginLeft: 10}}
              variant="outlined"
              size="small"
              value={this.state.order}
              onChange={this.handleChange}
            />
            {item.category !== "note" && <Fragment>
              <TextField
                label="URL link"
                placeholder="https://"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true, classes: {root: classes.label} }}
                InputProps={{ classes: {input: classes.textField} }}
                variant="outlined"
                size="small"
                name="url"
                value={this.state.url}
                onChange={this.handleChange}
              />
              <TextField
                label="Title"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true, classes: {root: classes.label} }}
                InputProps={{ classes: {input: classes.textField} }}
                variant="outlined"
                size="small"
                name="title"
                value={this.state.title}
                onChange={this.handleChange}
              />
              <TextField
                label="Description"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true, classes: {root: classes.label} }}
                InputProps={{ classes: {input: classes.textField} }}
                variant="outlined"
                size="small"
                multiline={true}
                name="description"
                value={this.state.description}
                onChange={this.handleChange}
              />
              <TextField
                label="Address"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true, classes: {root: classes.label} }}
                InputProps={{ classes: {input: classes.textField} }}
                variant="outlined"
                size="small"
                name="address"
                value={this.state.address}
                onChange={this.handleChange}
              />
            </Fragment>}
            <TextField
              label="Private notes"
              fullWidth
              margin="normal"
              InputLabelProps={{ shrink: true, classes: {root: classes.label} }}
              InputProps={{ classes: {input: classes.textField} }}
              variant="outlined"
              size="small"
              multiline={true}
              name="notes"
              value={this.state.notes}
              onChange={this.handleChange}
            />
          </Box>

          <Box style={{height: 40}}>
            {item.id && !this.state.isSaving && <Button size="small" className={`${classes.buttonDelete}`} onClick={this.handleDelete}>
              <Icon className={`${classes.icon} material-icons-outlined notranslate`}>delete</Icon>
              {this.state.isDeleting ? 'Deleting...' : 'Delete'}
            </Button>}
            <Box style={{float: 'right', display: 'block', minHeight: 30}}>
              <Button size="small" className={`${classes.buttonCancel}`} onClick={this.handleCancel.bind(this, item)}>
                Cancel
              </Button>
              {!this.state.isDeleting && <Button size="small" className={`${classes.buttonBook}`} onClick={this.handleSave}>
                {this.state.isSaving ? 'Saving...' : 'Save'}
              </Button>}
            </Box>
          </Box>
        </Box>
      </Box>)
    )
  }
}

const styles = theme => ({
  buttonBook: {
    zIndex: 2,
    color: '#fff',
    margin: '0px 0px 0px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    borderRadius: 25,
    fontWeight: 800,
    padding: '10px 15px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  buttonCancel: {
    zIndex: 2,
    margin: '0px 0px 0px 0px',
    textTransform: 'none',
    borderRadius: 25,
    padding: '10px 12px',
    fontWeight: 600,

    '&:hover': {
      boxShadow: 'none',
    }
  },
  buttonDelete: {
    zIndex: 2,
    margin: '0px 0px 0px 0px',
    textTransform: 'none',
    borderRadius: 25,
    padding: '5px 10px 5px 5px',
    color: 'red',
    fontWeight: 600,

    '&:hover': {
      boxShadow: 'none',
    }
  },
  iconNumber: {
    backgroundColor: "transparent",
    color: '#333',
    fontWeight: 900,
    fontSize: 16,
    padding: "0px 9px",
  },
  icon: {
    verticalAlign: 'middle !important',
    padding: '4px !important',
    fontSize: 18,
  },
  itineraryRoot: {
    margin: '10px 0px 0px 0px',
    marginLeft: "65px !important",
    width: 'auto',
    padding: 5,
    position: 'relative',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '25px',

    [theme.breakpoints.down('xs')]: {
      margin: '10px 0px',
      marginLeft: "0px !important",
    },
  },
  itineraryTextOnly: {
    margin: '20px 0px',
    border: '1px solid #eee',
    boxShadow: '0 0px 10px 0 rgb(0 0 0 / 5%)',
    width: 'auto',
    padding: '5px 20px',
    position: 'relative',
    zIndex: 2,
    borderRadius: '15px !important',
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 500,

    '& > ul': {
      paddingInlineStart: '20px',
    },

    [theme.breakpoints.down('xs')]: {

    },
  },
  itineraryCard: {
    margin: '20px 0px',
    boxShadow: '0 0px 10px 0 rgb(0 0 0 / 5%)',
    border: '1px solid #eee',
    width: 'auto',
    padding: 10,
    position: 'relative',
    zIndex: 2,
    borderRadius: '15px !important',
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 500,

    [theme.breakpoints.down('xs')]: {
      margin: '10px 0px',
      padding: '5px',
    },
  },
  itineraryRootNote: {
    margin: '10px 0px',
    marginLeft: 65,
    width: 'auto',
    padding: 5,
    position: 'relative',
    zIndex: 2,
    borderRadius: '15px !important',
    backgroundColor: 'transparent',
    fontSize: 14,
    fontWeight: 500,

    [theme.breakpoints.down('xs')]: {
      margin: '10px 0px',
    },
  },
  itineraryRail: {
    textAlign: 'left',

    '&::before': {
      position: 'absolute',
      zIndex: 0,
      left: 31,
      width: 2,
      content: '""',
      top: 30,
      height: 'calc(100% - 90px)',
      background: '#f2f2f2',
    },
  },
  itineraryIconRoot: {
    float: 'left',
    zIndex: 3,
    position: 'absolute',
    left: 13,
    display: 'block',
    marginTop: 10,
    color: '#333',
    backgroundColor: '#c6e8c9',
    boxShadow: 'none',

    [theme.breakpoints.down('xs')]: {
      marginTop: 20,
    },
  },
  itineraryTitle: {
    fontSize: '18px',
    fontWeight: 900,
    padding: '0px 0px 0px',
    marginBottom: 10,

    [theme.breakpoints.down('xs')]: {
      minHeight: 35,
      padding: '10px 0px 0px 55px',
    },
  },
  itineraryForm: {
    fontSize: '14px',
    fontWeight: 500,
    padding: '10px 0px 15px',
  },
  itineraryNotes: {
    fontSize: '14px',
    fontWeight: 500,
    padding: '10px 0px 15px',
    borderBottom: '1px solid #f2f2f2',
  },
  itineraryOnlyNotes: {
    fontSize: '14px',
    fontWeight: 500,
    padding: '0px',
    lineHeight: '22px',
    whiteSpace: 'pre-line',

    [theme.breakpoints.down('xs')]: {
      minHeight: 17,
      padding: '13px 0px 7px 55px',
    },
  },
  itineraryDescription: {
    fontSize: '14px',
    fontWeight: 500,
    padding: '10px 0px 15px',
    whiteSpace: 'pre-line',
  },
  itineraryLinkBtn: {
    float: 'right',
    padding: '6px 12px',
    color: '#02a986',
    backgroundColor: '#fff',
    textDecoration: 'underline',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    // border: '2px solid #02c39a',
    marginTop: 4,

    '&:hover': {
      backgroundColor: '#f2f2f2',
      // border: '2px solid #02c39a',
    }
  },
  itinerarySelectOption: {
    fontSize: 14,
    fontWeight: 500,
  },
  label: {
    fontSize: 16,
    fontWeight: 500,
    backgroundColor: '#fff',
  },
  selectLabel: {
    fontSize: 16,
    fontWeight: 500,
    backgroundColor: '#fff',
    marginLeft: '-4px',
    padding: '0px 7px',
  },
  select: {
    fontSize: 14,
    fontWeight: 500,
    paddingTop: 13,
    paddingBottom: 13,
  },
  textField: {
    fontSize: 14,
    fontWeight: 500,
    paddingTop: 14,
    paddingBottom: 14,
    lineHeight: '22px',
  }
});

export default withStyles(styles)(ItineraryItem);