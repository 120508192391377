import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import Cookies from 'universal-cookie'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  List,
  Hidden,
  Typography,
  Grid,
  Paper,
  withStyles,
} from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import { AvatarGroup, Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import TripHeader from './TripHeader'
import TripBookingBar from './TripBookingBar'
import TripBookingBox from './TripBookingBox'
import Itinerary from '../itinerary/Itinerary'
import SearchPanel from '../SearchPanel'
import { config } from '../../config.js'

const API = config.api.TRAVEL_URL
const cookies = new Cookies();

class TripItinerary extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editable: false,
      isLoading: false,
      itinerary: [],
      destination: props.destination,
      search_params: {},
      planned: props.planned || false,
      program_planned: false,
      title: null,

      rating: null,
      ratingHover: null,

      error: null,
      programIntervalId: null,
    }
  }

  componentDidMount() {
    if (!this.state.isLoading) {
      this.fetchTrip(this.props.trip_id)
    }
  }

  isSize = (size) => {
    return isWidthDown(size, this.props.width)
  }

  storeTrip(data) {
    let params = {
      title: data.trip.title,
      isLoading: false,
      isRefreshing: (data.trip.program_status !== null),
      editable: (data.is_creator ? true : false),
      planned: data.trip.planned,
      program_planned: data.trip.program_planned,
      itinerary: data.itinerary,
      flight: data.trip.flight,
      hotel: data.trip.hotel,
      search_params: data.search_params,
      destination: {
        from: data.trip.from.city,
        from_airports: data.trip.from.airports,
        to: data.trip.to.address,
        to_airports: data.trip.to.airports,
        to_longitude: data.trip.to.longitude,
        to_latitude: data.trip.to.latitude,
        start_date: data.destination.start_date,
        end_date: data.destination.end_date,
        nights_in_dest: data.destination.nights_in_dest,
        adults: data.search_params.adults,
        children: data.search_params.children,
        infants: data.search_params.infants,
        images: data.destination.images,
        intro: data.destination.intro,
        score: data.destination.score,
        saved: data.saved,
        link_to_save: data.link_to_save,
        slug: data.destination.slug,
      },
    }

    this.props.onItineraryUpdated({ destination: params['destination'], flight: params['flight'], hotel: params['hotel'] })
    this.setState(params)
  }

  fetchTrip(trip_id) {
    this.setState({isLoading: true})
    fetch(API + `/trips/${trip_id ? trip_id : ''}?`
    ,{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid'),
      }
    })
    .then(response => response.json())
    .then(data => {
      if (data.trip) {
        this.storeTrip(data)
        this.setState({isLoading: false})

        this.props.history.replace(this.props.sharableLink)
      } else {
        this.setState({isLoading: false, planned: true, error: data.error})
      }
    })
  }

  customizeTrip(trip_id) {

  }

  render() {
    const { classes, trip_id, user, sharableLink, hideHeader, travel_plan_id } = this.props;
    const { itinerary, destination, search_params, hotel, flight, error, program_planned, isRefreshing, title, isLoading, rating, ratingHover } = this.state
    const adults = (destination && destination.adults) || 1
    const children = (destination && destination.children) || 0
    const travelers = adults + children


    return(
      <Fragment>
          {error && <Grid item xs={12} sm={12} md={12} id="top_container" style={{margin: 'auto'}}>
            <div className={classes.travelStyleContainer} style={{marginBottom: 0, marginTop: 0}}>
              <Alert
                severity="error"
                style={{margin: 'auto', textAlign: 'left', borderRadius: 15, fontWeight: 500}}
              >
                <AlertTitle style={{fontWeight: 700, fontSize: 15}}>{error}</AlertTitle>
                <div>This might be an old trip that is no longer available.</div>
              </Alert>
            </div>
          </Grid>}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} id="top_trip_container" style={{margin: 'auto', paddingBottom: 70}}>

          {!error && <Fragment>
            {!hideHeader && <TripHeader
              adults={destination && destination.adults}
              children={destination && destination.children}
              destination={this.state.destination || this.state.destinationDetails}
              endDate={destination && destination.end_date}
              infants={destination && destination.infants}
              isLoading={!destination}
              nights={destination && destination.nights_in_dest}
              score={destination && destination.score}
              saved={destination && destination.saved}
              link_to_save={destination && destination.link_to_save}
              searchParams={search_params}
              sharableLink={sharableLink}
              startDate={destination && destination.start_date}
              to={destination ? destination.to : this.props.locationTo}
              title={title}
              plan={this.props.plan}
              travel_plan_id={this.props.travel_plan_id}
              // hideGallery={config.env === "development"}
              // hideGallery={true}
              onSelectedTab={this.handleTabSelection}
              user={this.props.user}
            />}

            <Hidden mdUp>
              <TripBookingBox
                travel_price={this.state.flight && this.state.flight.price}
                hotel_price={this.state.hotel && this.state.hotel.total_price}
                start_date={this.state.destination && this.state.destination.start_date}
                end_date={this.state.destination && this.state.destination.end_date}
                nights={this.state.destination && this.state.destination.nights_in_dest}
                travelers={travelers}
                currency={this.props.currency}
              />
            </Hidden>

            {/* <div className={classes.route}>
              <div style={{padding: '50px 0px 60px 0px'}}>
                <SearchPanel
                  disabled={true}
                  planner={false}
                  center={false}
                  hideFieldTo={true}
                  hideSearchButton={false}
                  show_description={false}
                  expandable={this.isSize('xs')}
                  search_params={this.props.search_params}
                  onSearch={this.props.onSearch}
                />
              </div> */}


              {/* Don't render itinerary when travel plan and loading */}
              {(!travel_plan_id || (travel_plan_id && !isLoading)) && <Itinerary
                // editable={this.state.editable}
                // editable={true}
                itinerary={itinerary}
                itineraryType={"Trip"}
                itineraryId={trip_id}
                itineraryUrl={sharableLink}
                isRefreshing={isRefreshing}
                destination={destination}
                user={user}
                search_params={search_params}
                itineraryLoaded={!isLoading}
                onSignin={this.props.onSignin}
                onSearch={this.props.onSearch}
              />}
            {/* </div> */}

          </Fragment>}
          </Grid>

          <Hidden smDown>
            <Grid item xs={12} sm={12} md={4}>
              <div className="booking-container sticky">
                <TripBookingBox
                  travel_price={this.state.flight && this.state.flight.price}
                  hotel_price={this.state.hotel && this.state.hotel.total_price}
                  start_date={this.state.destination && this.state.destination.start_date}
                  end_date={this.state.destination && this.state.destination.end_date}
                  nights={this.state.destination && this.state.destination.nights_in_dest}
                  travelers={travelers}
                  currency={this.state.currency}
                />
              </div>
            </Grid>
          </Hidden>

          {/* <Hidden mdUp> */}
          {/* {itinerary.length > 0 && <TripBookingBar
              isLoading={this.state.isLoading}
              trip_id={trip_id}
              user={user}
              destination={destination}
              flight={flight}
              hotel={hotel}
              hotelBookingLink={hotel && (hotel.booking_url + (hotel.booking_url.includes("?") ? "&" : "?") + "checkIn=" + destination.start_date + "&checkOut=" + destination.end_date + "&adults=" + destination.adults + "&children=" + destination.children)}
              search_params={search_params}
            />} */}
          {/* </Hidden> */}
        </Grid>
      </Fragment>
    )
  }
}

const styles = theme => ({
  route: {
    position: 'relative',
    maxWidth: 750,

    '&::before': {
      position: 'absolute',
      zIndex: 1,
      left: 31,
      width: 2,
      content: '""',
      top: -5,
      height: '108%',
      background: '#f2f2f2',
    }
  },
});

export default withRouter(withStyles(styles)(withWidth()(TripItinerary)));